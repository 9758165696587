@import '~antd/dist/reset.css';

body, html {
    min-height: 100%;
    width: 100%;
    background-color: #f9f9f9;
}

#root {
    min-height: 100%;
    flex: 1;
}